.loading.cover-content {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.loading.cover-page {
	position: fixed;
	width: 100%;
	height: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}
.loading .ant-spin {
	color: #3e79f7;
}

.custom-file-input::-webkit-file-upload-button {
	visibility: hidden;
}
.custom-file-input::before {
	text-align: center;
	content: 'Upload Image';
	display: inline-block;
	background: linear-gradient(top, #f9f9f9, #e3e3e3);
	border: 2px solid rgb(162 170 175);
	border-radius: 8px;
	color: #000000;
	padding: 5px 8px;
	outline: none;
	white-space: nowrap;
	-webkit-user-select: none;
	cursor: pointer;
	font-size: 14px;
}
.custom-file-input:hover::before {
	border-color: black;
}
.custom-file-input:active::before {
	background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.w-full {
	width: 100%;
}
.gift-button {
	background-image: linear-gradient(
		to right,
		#ff8008 0%,
		#ffc837 51%,
		#ff8008 100%
	);
	padding: 12px 45px;
	text-align: center;
	transition: 0.5s;
	background-size: 200% auto;
	color: #111;
	box-shadow: 0 0 20px #eee;
	border-radius: 100px;
	display: block;
	cursor: pointer;
	border: 0;
}

.gift-button:hover {
	background-position: right center;
	color: #fff;
	text-decoration: none;
}

.overflow-scroll {
	overflow: scroll;
}
